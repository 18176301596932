.cardRootDiv {
  max-width: 350px;
  width: 80vw;
  height: 70vh;
  min-height: 550px;
}

@media screen and (min-width: 992px) {
  .cardRootDiv {
    max-width: calc(92vw / 4);
    height: 100%;
    min-height: 530px;
    padding: 5px;
  }
}

@media screen and (min-width: 1400px) {
  .cardRootDiv {
    min-height: 650px;
  }

  .pointsHeading {
    font-size: 15px !important;
  }

  .upperPartHeading {
    font-size: 17px !important;
  }
}

@media screen and (min-width: 2560px) {
  .cardRootDiv {
    max-width: calc(80vw / 4);
    min-height: 900px;
    padding: 35px;
  }

  .pointsHeading {
    font-size: 20px !important;
  }

  .upperPartHeading {
    font-size: 23px !important;
  }
}
