@media screen and (min-width: 992px) {
  .instruction {
    width: 350px;
  }
}

@media screen and (min-width: 1200px) {
  .slideNumber {
    font-size: 75px !important;
  }
}

@media screen and (min-width: 1400px) {
  .slideNumber {
    font-size: 75px !important;
  }
  .instruction {
    width: 500px;
  }
}

@media screen and (min-width: 2560px) {
  .instruction {
    width: 850px;
  }
  .slideNumber {
    font-size: 140px !important;
  }
}
