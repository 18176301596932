.prizes {
  background: url("https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/prizesMobile.jpg")
    no-repeat;
  background-size: 100% auto;
  width: 100%;
  height: 57vw;
}

@media screen and (min-width: 992px) {
  .prizes {
    background: url("https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/prizesDesktop.jpg")
      no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 44vw;
  }
}
