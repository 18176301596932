.roundedCorners {
  border-radius: 50px !important;
}

.darkBg {
  background-color: #302d2d;
}

.whiteCl {
  color: white !important;
}

.whiteBorder {
  border: 1px solid white !important;
}

.whiteBg {
  background-color: white !important;
}

.redCl {
  color: #b91414 !important;
}

.redBg {
  background-color: #b91414 !important;
}

.yellowCl {
  color: #ffc300 !important;
}

.yellowBg {
  background-color: #ffc300 !important;
}

.blackCl {
  color: black !important;
}

.blackBg {
  background-color: black !important;
}

.removeStroke:focus {
  outline: 0 !important;
}

.blueBg {
  background-color: #eef8fe !important;
}

.blueGradientBg {
  background: linear-gradient(white, #eef8fe) !important;
}

.textTransformNone {
  /* for buttons */
  text-transform: none !important;
}

/* classes below this are for each tab's root file*/

.root {
  padding-top: 25px;
}

.carouselDiv {
  margin-top: 25px;
}

.redHeading {
  margin-right: 0.5rem;
}

@media screen and (min-width: 992px) {
  /* classes below this are for each tab's root file*/

  .root {
    padding-top: 45px;
  }

  .carouselDiv {
    margin-top: 45px;
  }

  .heading {
    font-size: 30px !important;
  }

  .subHeading {
    font-size: 15px !important;
  }

  .redHeading {
    margin-right: 15px !important;
  }
}

@media screen and (min-width: 1200px) {
  /* classes below this are for each tab's root file*/
  .subHeading {
    font-size: 18px !important;
  }
}

@media screen and (min-width: 1400px) {
  /* classes below this are for each tab's root file*/
  .subHeading {
    font-size: 22px !important;
  }
}

@media screen and (min-width: 2560px) {
  /* classes below this are for each tab's root file*/

  .root {
    padding-top: 55px;
  }

  .carouselDiv {
    margin-top: 55px;
  }

  .heading {
    font-size: 50px !important;
  }

  .redHeading {
    margin-right: 25px !important;
  }

  .subHeading {
    font-size: 30px !important;
  }
}
