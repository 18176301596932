.cardRootDiv {
  max-width: 350px;
  width: 80vw;
  height: 70vh;
  border: 1px solid white;
  padding: 15px;
}

@media screen and (min-width: 992px) {
  .cardRootDiv {
    max-width: calc(80vw / 4);
    height: 100%;
    min-height: 500px;
    padding: 5px;
  }
}

@media screen and (min-width: 2560px) {
  .cardRootDiv {
    max-width: calc(80vw / 4);
    min-height: 700px;
    padding: 35px;
  }
}
