.root {
  padding-top: 1rem;
  padding-bottom: 1.5rem;
}

.logoDiv {
  width: 100%;
}

.logo {
  width: 100px;
  /* height: auto; */
  height: 100px;
}

.copyRightsDiv {
  margin-top: 1.5rem;
}

.socialMediaButtonsDiv {
  margin-top: 1.5rem;
}

.socialMediaButtonImg {
  width: 40px;
  height: 40px;
}

.downloadButtons {
  width: 135px;
  height: auto;
}

@media screen and (min-width: 992px) {
  .logoDiv {
    width: 20%;
  }
}

@media screen and (min-width: 1400px) {
  .copyRights {
    font-size: 18px !important;
  }
  .logo {
    width: 110px;
    /* height: auto; */
    height: 110px;
  }
}

@media screen and (min-width: 2560px) {
  .root {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .copyRights {
    font-size: 24px !important;
  }
  .logo {
    width: 190px;
    /* height: auto; */
    height: 190px;
  }
  .socialMediaButtonImg {
    width: 70px;
    height: 70px;
  }
  .socialMediaButtonLeftDiv {
    margin-left: 25px;
  }
  .downloadButtons {
    width: 200px;
    height: auto;
  }
}
