.home {
  background: url("https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/screen1.jpg")
    no-repeat;
  background-size: 100% auto;
  /* background-position: center; */
  width: 100%;
  height: 144vw;
  position: relative;
}

.homeButton {
  position: absolute;
  bottom: 30px;
}

.button {
  padding: 1rem 4rem 1rem 4rem !important;
  font-size: 0.95rem !important;
}

@media screen and (min-width: 992px) {
  .home {
    background: url("https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/screen1D.jpg")
      no-repeat;
    background-size: 100% auto;
    height: 32vw;
  }
  .homeButton {
    bottom: calc(10%);
  }
  .button {
    padding: 10px 20px 10px 20px !important;
    margin-right: 18% !important;
  }
}

@media screen and (min-width: 1200px) {
  .homeButton {
    bottom: calc(11%);
  }
  .button {
    margin-right: 21.5% !important;
  }
}

@media screen and (min-width: 1400px) {
  .homeButton {
    bottom: calc(12%);
  }
  .button {
    padding: 20px 40px 20px 40px !important;
    margin-right: 21% !important;
  }
}

@media screen and (min-width: 1500px) {
  .button {
    margin-right: 22.2% !important;
  }
}

@media screen and (min-width: 1700px) {
  .homeButton {
    bottom: calc(13%);
  }
  .button {
    margin-right: 24.5% !important;
  }
}

@media screen and (min-width: 1900px) {
  .button {
    margin-right: 26.5% !important;
  }
}

@media screen and (min-width: 1900px) {
  .button {
    margin-right: 26.5% !important;
  }
}

@media screen and (min-width: 2100px) {
  .homeButton {
    bottom: 15%;
  }
  .button {
    margin-right: 28% !important;
  }
}

@media screen and (min-width: 2300px) {
  .homeButton {
    bottom: 17%;
  }
  .button {
    margin-right: 29% !important;
  }
}

@media screen and (min-width: 2500px) {
  .homeButton {
    bottom: 18%;
  }
  .button {
    margin-right: 30% !important;
  }
}

@media screen and (min-width: 3000px) {
  .homeButton {
    bottom: 18%;
  }
  .button {
    margin-right: 31.5% !important;
  }
}
