.imgDiv {
  max-width: 300px;
  width: 80%;
  height: auto;
  box-shadow: 2px 2px 5px 1px #888888;
  opacity: 0.5;
  cursor: pointer;
}

.imgDivActive {
  max-width: 300px;
  width: 90%;
  height: auto;
  box-shadow: 5px 5px 5px 3px #888888;
}

.img {
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 2560px) {
  .imgDiv {
    max-width: 500px;
    width: 90%;
    margin-left: 50px;
  }

  .imgDivActive {
    max-width: 450px;
    width: 350px;
    margin-left: 50px;
  }
}
